import React from "react";
import { FirebaseAuthProvider } from "react-admin-firebase";
import { Admin, Resource, ListGuesser, fetchUtils } from "react-admin";
import Amplify, { Auth } from "aws-amplify";
import { buildAuthProvider } from "react-admin-amplify";

import {
  CreateService,
  EditService,
  ListService,
  ShowService,
} from "./components/service";
import {
  CreateCategory,
  EditCategory,
  ListCategory,
  ShowCategory,
} from "./components/category";
import {
  CreatePackage,
  EditPackage,
  ListPackage,
  ShowPackage,
} from "./components/package";
import { ListUser, ShowUser } from "./components/users";
import { ListQuery, ShowQuery } from "./components/query";
import {
  CreateServiceRequest,
  EditServiceRequest,
  ListServiceRequest,
  ShowServiceRequest,
} from "./components/serviceRequest";

import {
  CreateSevak,
  EditSevak,
  ListSevak,
  ShowSevak,
} from "./components/sevak";

import {
  CreateAppointment,
  EditAppointment,
  ListAppointment,
  ShowAppointment,
} from "./components/appointment";

import {
  CreateSubscription,
  EditSubscription,
  ListSubscription,
  ShowSubscription,
} from "./components/subscription";

import crudProvider from "./dataProvider";
import config from "./aws-exports";

Amplify.configure(config);

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const session = await Auth.currentSession();
  if (session) {
    const token = session.getAccessToken().getJwtToken();
    options.headers.set("Authorization", `Bearer ${token}`);
  }
  // options.headers.set('"Access-Control-Allow-Headers", *');
  // options.headers.set('"Access-Control-Allow-Methods": *');
  return fetchUtils.fetchJson(url, options);
};

// const endpoint =
//   process.env.NODE_ENV === "PRODUCTION"
//     ? "https://gowehvvw05.execute-api.ap-south-1.amazonaws.com/dev"
//     : "http://localhost:3000";

// const dataProvider = crudProvider('http://35.154.163.39', httpClient);
const dataProvider = crudProvider(
  "https://gowehvvw05.execute-api.ap-south-1.amazonaws.com/dev",
  httpClient
);
const authProvider = buildAuthProvider({ authGroups: ["Employee"] });

function App() {
  return (
    <Admin authProvider={authProvider} dataProvider={dataProvider}>
      <Resource
        name="service-request"
        list={ListServiceRequest}
        show={ShowServiceRequest}
        edit={EditServiceRequest}
        options={{ label: "Incoming Orders" }}
      />
      <Resource
        name="appointment"
        list={ListAppointment}
        create={CreateAppointment}
        show={ShowAppointment}
        edit={EditAppointment}
        options={{ label: "Appointments" }}
      />
      <Resource
        name="subscription"
        list={ListSubscription}
        create={CreateSubscription}
        show={ShowSubscription}
        edit={EditSubscription}
        options={{ label: "Subscriptions" }}
      />
      <Resource
        name="service"
        list={ListService}
        create={CreateService}
        show={ShowService}
        edit={EditService}
        options={{ label: "Service" }}
      />
      {/* <Resource
        name="category"
        list={ListCategory}
        create={CreateCategory}
        show={ShowCategory}
        edit={EditCategory}
        options={{ label: "Category" }}
      /> */}
      <Resource
        name="package"
        list={ListPackage}
        create={CreatePackage}
        show={ShowPackage}
        edit={EditPackage}
        options={{ label: "Package" }}
      />
      <Resource
        name="user"
        list={ListUser}
        show={ShowUser}
        options={{ label: "User" }}
      />
      <Resource
        name="query"
        list={ListQuery}
        show={ShowQuery}
        options={{ label: "Query" }}
      />

      <Resource
        name="sevak"
        list={ListSevak}
        create={CreateSevak}
        show={ShowSevak}
        edit={EditSevak}
        options={{ label: "Seva Mitra" }}
      />
    </Admin>
  );
}

export default App;
