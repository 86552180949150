import React from "react";
import { List, Datagrid, TextField, ShowButton, EditButton } from "react-admin";

export const ListUser = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="phone" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
