import React from "react";
import {
  Edit,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  TextField,
  ArrayInput,
  SelectInput,
  AutocompleteInput,
  ArrayField,
  Datagrid,
  DateInput,
  ReferenceInput,
  NumberInput,
  required,
  choices,
  email,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

const validateGender = choices(
  ["Male", "Female", "Pefer not to answer"],
  "Choose one of the options."
);

const validateReqStatus = choices(
  ["OPEN", "RESPONDED", "CONFIRMED", "CLOSED"],
  "Choose on of the given options"
);

export const EditServiceRequest = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Name(optional)" source="name" validate={required()} />
      <DateInput
        label="Date of confirmation call"
        source="confirmationCallDate"
        validate={required()}
      />
      <TextInput
        label="Time Slot for confirmation call"
        source="confirmationCallTimeSlot"
        validate={required()}
      />
      {/* patients array input */}
      <ArrayInput source="patients">
        <SimpleFormIterator>
          <TextInput label="Patient Name" source="name" />
          <TextInput label="Patient Age" source="age" />
          <SelectInput
            label="Gender"
            source="gender"
            choices={[
              { id: "Male", name: "Male" },
              { id: "Female", name: "Female" },
              { id: "Pefer not to answer", name: "Pefer not to answer" },
            ]}
            validate={validateGender}
          />
          <TextInput label="Patient phone" source="phone" />
        </SimpleFormIterator>
      </ArrayInput>

      {/* address block */}
      {/* <TextInput label="Google PlaceId" source="googlePlaceId" />
      <TextInput label="Patient Name" source="name" />
      <TextInput label="Patient Name" source="name" />
      <TextInput label="Patient Name" source="name" /> */}

      <RichTextInput label="Additional Info" source="message" />

      <SelectInput
        label="Service Request Status"
        source="requestStatus"
        choices={[
          { id: "OPEN", name: "Open" },
          { id: "RESPONDED", name: "Responded" },
          { id: "CONFIRMED", name: "Confirmed" },
          { id: "CLOSED", name: "Closed" },
        ]}
        validate={validateReqStatus}
      />

      <SelectInput
        label="Payment Status"
        source="paymentStatus"
        choices={[
          { id: "UNPAID", name: "Unpaid" },
          { id: "PAID", name: "Paid" },
        ]}
      />

      <RichTextInput label="Cost breakdown" source="costBreakdown" />

      {/* i18n fields */}
      {/* <TextField label="Translation Fields" />
      <TextInput
        label="Message(English)"
        source="i18n.en.message"
        validate={required()}
      />
      <TextInput
        label="Cost Breakdown(English)"
        source="i18n.en.costBreakdown"
        validate={required()}
      />

      <TextInput label="Message(Assamese)" source="i18n.as.message" />
      <TextInput
        label="Cost Breakdown(Assamese)"
        source="i18n.as.costBreakdown"
      />
      <TextInput label="Name(Hindi)" source="i18n.hi.message" />
      <TextInput label="Cost Breakdown(Hindi)" source="i18n.hi.costBreakdown" /> */}
      {/* end i18n fields */}

      <TextInput label="Total Amount to be Paid" source="totalAmount" />

      {/* paylink */}
      <TextInput label="Pay-Link URL" source="payLink" />

      {/* <ArrayInput source="package">
        <SimpleFormIterator>
          <ReferenceInput
            label="PackageID"
            source="package.id"
            reference="package"
          >
            <SelectInput optionText="name" resettable />
          </ReferenceInput>
          <ReferenceInput
            label="Package Name"
            source="package.name"
            reference="package"
          >
            <SelectInput optionText="name" optionValue="name" resettable />
          </ReferenceInput>
          <NumberInput label="Days" source="days" />
        </SimpleFormIterator>
      </ArrayInput> */}
      {/* <ArrayField source="service">
        <Datagrid>
          <TextField source="id" />
          <TextField source="days" />
          <TextField source="name" />
        </Datagrid>
      </ArrayField>

      <ArrayField source="package">
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="service" />
        </Datagrid>
      </ArrayField> */}

      {/* <ReferenceInput
        label="Request for User"
        source="requestUser.id"
        reference="user"
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput> */}
      <TextInput
        label="User Email"
        source="user"
        validate={(required(), email())}
      />
    </SimpleForm>
  </Edit>
);
