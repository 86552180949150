import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  BooleanField,
  DateField,
  ReferenceField,
  EditButton,
} from "react-admin";

export const ListService = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="rank" />
      <BooleanField source="show" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
