import React from "react";
import { List, Datagrid, TextField, ShowButton, EditButton } from "react-admin";

export const ListSevak = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="accountStatus" />

      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
