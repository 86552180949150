import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";

export const ShowUser = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="age" />
      <TextField source="gender" />
      <TextField source="phone" />
      <TextField source="language" />

      {/* <ReferenceArrayField
        label="Subscriptions"
        reference="subscription"
        source="subscriber"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        label="Appointments"
        reference="appointments"
        source="appointment"
      >
        <SingleFieldList>
          <ChipField source="purpose" />
        </SingleFieldList>
      </ReferenceArrayField> */}
    </SimpleShowLayout>
  </Show>
);
