import React from "react";
import { Show, SimpleShowLayout, TextField, ImageField } from "react-admin";

export const ShowSevak = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="phone" />
      <TextField source="designation" />
      <TextField source="averageRating" />
    </SimpleShowLayout>
  </Show>
);
