import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  BooleanField,
  DateField,
  NumberField,
  EditButton,
} from "react-admin";

export const ListPackage = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <NumberField source="rank" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
