import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DateField,
  ReferenceField,
} from "react-admin";

export const ListSubscription = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="title" />
      <TextField label="Order ID" source="serviceRequestId" />
      <DateField label="Request Date" source="requestDate" />
      <TextField label="Days" source="days" />

      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
