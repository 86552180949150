import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DateField,
  ReferenceField,
} from "react-admin";

export const ListAppointment = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="title" />
      <DateField label="Request Date" source="requestDate" />

      <DateField label="Finalised Date" source="finalisedDate" />
      <TextField label="Requested By" source="user" />

      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
