import * as React from "react";
import { Show, SimpleShowLayout, BooleanField, TextField, RichTextField, NumberField, ArrayField, Datagrid, ReferenceField } from 'react-admin';
import { S3ImageField } from '../shared/fields'

export const ShowPackage = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <S3ImageField source="image" />
            <TextField source="brief" />
            <RichTextField source="description" />
            <NumberField source="rank" />
            {/* <NumberField source="price" /> */}
            <BooleanField source="show" />
            <BooleanField source="featured" />
            <ArrayField source="service">
                <Datagrid>
                    <TextField source="name" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);