import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Datagrid,
  ArrayField,
  ReferenceField,
  RichTextField,
} from "react-admin";
import { S3FileField } from "../shared/fields";

export const ShowAppointment = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="Generated For User" source="user" />
      <TextField label="Generated With Order ID" source="serviceRequestId" />
      <DateField source="requestDate" />
      <TextField source="requestTimeSlot" />
      {/* <DateField source="finalisedDate" />
      <TextField souce="finalisedTimeSlot" /> */}

      <RichTextField source="brief" />
      <TextField source="address" />
      <ArrayField source="patients">
        <Datagrid>
          <TextField source="name" />
          <TextField source="age" />
          <TextField source="gender" />
          <TextField source="phone" />
        </Datagrid>
      </ArrayField>

      <ArrayField source="files">
        <Datagrid>
          <S3FileField />
        </Datagrid>
      </ArrayField>

      {/* <ReferenceField source="sevakAssigned" reference="sevak">
        <TextField source="name" />
      </ReferenceField> */}
      <TextField label="Seva Mitra Assigned" source="sevakAssigned.name" />
    </SimpleShowLayout>
  </Show>
);
