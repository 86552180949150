/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:599a4c95-35eb-4162-bda6-f089727298f1",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_sGlBiSmkl",
    "aws_user_pools_web_client_id": "1ghd00buqlilip6e7oievalmgt",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://gr9td4h7ei.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        }
    ],
    "aws_user_files_s3_bucket": "lb-seva-asset181440-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
