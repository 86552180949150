import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  ReferenceInput,
  FileInput,
  FileField,
  DateInput,
  required,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  choices,
  AutocompleteInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { S3ImageInput } from "../shared/input";

const validateGender = choices(
  ["male", "female", "other"],
  "Choose one of the options."
);

export const EditSubscription = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField label="Generated For User" source="user.name" />
      <TextField
        label="Generated With Service Request"
        source="serviceRequestId"
      />
      <TextInput label="Title" source="title" />
      <DateField label="Date Requested" source="requestDate" />
      <TextField label="Time Requested" source="requestTimeSlot" />
      <TextInput label="Number of Days" source="days" />

      <ArrayInput source="finalisedDates">
        <SimpleFormIterator>
          <TextInput label="Date" source="date" />
          <TextInput label="Time Slot" source="timeSlot" />
        </SimpleFormIterator>
      </ArrayInput>

      <RichTextInput label="Brief" source="brief" />
      <TextInput label="Address" source="address" />

      {/* i18n fields */}
      <TextField label="Translation Fields" />
      <TextInput label="Title(English)" source="i18n.en.title" />
      <TextInput label="Brief(English)" source="i18n.en.brief" />
      <TextInput label="Title(Assamese)" source="i18n.as.title" />
      <TextInput label="Brief(Assamese)" source="i18n.as.brief" />
      <TextInput label="Title(Hindi)" source="i18n.hi.title" />
      <TextInput label="Brief(Hindi)" source="i18n.hi.brief" />

      {/* end i18n fields */}

      <ArrayInput source="patients">
        <SimpleFormIterator>
          <TextInput label="Patient Name" source="name" />
          <TextInput label="Patient Age" source="age" />
          <SelectInput
            label="Gender"
            source="gender"
            choices={[
              { id: "male", name: "Male" },
              { id: "female", name: "Female" },
              { id: "other", name: "Other" },
            ]}
            validate={validateGender}
          />
          <TextInput label="Patient phone" source="phone" />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="files">
        <SimpleFormIterator>
          <S3ImageInput
            label="File/Report Upload"
            source="image"
            level="public"
            validate={required()}
          />
        </SimpleFormIterator>
      </ArrayInput>

      <ReferenceInput
        label="Assign Sevak"
        source="sevakAssigned"
        reference="sevak"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
