import React from "react";
import {
  Show,
  SimpleShowLayout,
  Datagrid,
  ArrayField,
  TextField,
  RichTextField,
} from "react-admin";

import { S3ImageField, S3Field, S3ImageArrayField, S3FileArrayField } from '../shared/fields'

export const ShowServiceRequest = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="confirmationCallDate" />
      <TextField source="confirmationCallTimeSlot" />
      {/* patients array */}
      <ArrayField source="patients">
        <Datagrid>
          <TextField source="name" />
          <TextField source="age" />
          <TextField source="gender" />
          <TextField source="phone" />
        </Datagrid>
      </ArrayField>
      <S3FileArrayField source="image" />
      <RichTextField source="message" />
      <TextField source="address" />
      <TextField source="requestStatus" />
      <TextField source="paymentStatus" />
      <RichTextField source="costBreakdown" />
      {/* i18n fields */}
      {/* <TextField label="Translation Fields" />
      <TextField label="Message(English)" source="i18n.en.message" />
      <TextField
        label="Cost Breakdown(English)"
        source="i18n.en.costBreakdown"
      />

      <TextField label="Message(Assamese)" source="i18n.as.message" />
      <TextField
        label="Cost Breakdown(Assamese)"
        source="i18n.as.costBreakdown"
      />
      <TextField label="Name(Hindi)" source="i18n.hi.message" />
      <TextField label="Cost Breakdown(Hindi)" source="i18n.hi.costBreakdown" /> */}
      {/* end i18n fields */}
      <TextField source="payLink" />
      <ArrayField source="service">
        <Datagrid>
          <TextField source="id" />
          <TextField source="days" />
          <TextField source="name" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="package">
        <Datagrid>
          <TextField source="name" />
          <ArrayField source="service">
            <Datagrid>
              <TextField source="price" />
              <TextField source="name" />
              <TextField source="days" />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>
      <TextField label="Service Requested By User" source="user" />
    </SimpleShowLayout>
  </Show>
);
