import React from "react";
import {
  Create,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  ArrayInput,
  SelectInput,
  DateInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  NumberInput,
  required,
  choices,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

const validateGender = choices(
  ["male", "female", "other"],
  "Choose one of the options."
);

const validateReqStatus = choices(
  ["OPEN", "RESPONDED", "CONFIRMED", "CLOSED"],
  "Choose on of the given options"
);

export const CreateServiceRequest = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Name(optional)" source="name" validate={required()} />
      <DateInput
        label="Date of confirmation call"
        source="confirmationCallDate"
        validate={required()}
      />
      <TextInput
        label="Time Slot for confirmation call"
        source="confirmationCallTimeSlot"
        validate={required()}
      />
      {/* patients array input */}
      <ArrayInput source="patients">
        <SimpleFormIterator>
          <TextInput label="Patient Name" source="name" />
          <TextInput label="Patient Age" source="age" />
          <SelectInput
            label="Gender"
            source="gender"
            choices={[
              { id: "male", name: "Male" },
              { id: "female", name: "Female" },
              { id: "other", name: "Other" },
            ]}
            validate={validateGender}
          />
          <TextInput label="Patient phone" source="phone" />
        </SimpleFormIterator>
      </ArrayInput>

      {/* address block */}
      {/* <TextInput label="Google PlaceId" source="googlePlaceId" />
      <TextInput label="Patient Name" source="name" />
      <TextInput label="Patient Name" source="name" />
      <TextInput label="Patient Name" source="name" /> */}

      <RichTextInput label="Additional Info" source="additionalInfo" />

      <SelectInput
        label="Service Request Status"
        source="requestStatus"
        choices={[
          { id: "OPEN", name: "Open" },
          { id: "RESPONDED", name: "Responded" },
          { id: "CONFIRMED", name: "Confirmed" },
          { id: "CLOSED", name: "Closed" },
        ]}
        validate={validateReqStatus}
      />

      <SelectInput
        label="Payment Status"
        source="paymentStatus"
        choices={[
          { id: "UNPAID", name: "Unpaid" },
          { id: "PAID", name: "Paid" },
        ]}
      />

      <RichTextInput label="Cost breakdown" source="costBreakdown" />

      <NumberInput label="Total Amount to be Paid" source="totalAmount" />

      {/* paylink */}

      {/* <ArrayInput source="orderItems">
        <SimpleFormIterator>
        
          <ReferenceInput
            label="Package(Optional)"
            source="packageId"
            reference="package"
          >
            <SelectInput optionText="name" resettable />
          </ReferenceInput>
          <ReferenceInput
            label="Service"
            source="serviceId"
            reference="service"
          >
            <SelectInput optionText="name" resettable />
          </ReferenceInput>
          <NumberInput label="Days" source="days" />
        </SimpleFormIterator>
      </ArrayInput> */}

      <ArrayInput source="service">
        <SimpleFormIterator>
          <ReferenceInput
            label="Service Name"
            source="name"
            reference="service"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput label="Days" source="days" />
          <NumberInput label="Price" source="price" />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="package">
        <SimpleFormIterator>
          <ReferenceInput
            label="Package Name"
            source="name"
            reference="package"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput label="Price" source="price" />
          <ReferenceArrayInput
            label="Package Services"
            source="service"
            reference="service"
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        </SimpleFormIterator>
      </ArrayInput>

      <ReferenceInput
        label="Request for User"
        source="requestUser"
        reference="user"
      >
        <SelectInput optionText="name" resettable />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
