import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    SingleFieldList,
    ChipField,
} from "react-admin";

export const ShowQuery = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="query" />
            <ReferenceField
                source="user.id"
                reference="user"
                label="User"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
)  