import React from 'react';
import { S3Field } from './S3Field';
import { S3Image } from './S3Image';
import { S3File } from './S3File'

export interface S3ImageFieldProps {
    record?: Record<string, any>;
    source?: string;
    imgProps?: any;
    addLabel?: boolean;
}

export const S3ImageArrayField: React.FC<S3ImageFieldProps> = ({
    source,
    record = {},
    // to avoid html img prop errors
    imgProps = {},
    ...props
}) => {
    const images =
        source && typeof record[source] === 'object' ? record[source] : record;

    return images.map(
        img => <S3Field record={{ image: img }} source="image">
            <S3Image imgProps={imgProps} />
        </S3Field>
    )
};

S3ImageArrayField.defaultProps = {
    addLabel: true,
};