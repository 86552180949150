import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceArrayInput,
  BooleanInput,
  DateField,
  FileInput,
  FileField,
  TextField,
  required,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import SelectArrayInput from "../shared/SelectArrayInput";
import { S3ImageInput } from "../shared/input";

export const EditService = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <S3ImageInput source="image" level="public" validate={required()} />
      <TextInput multiline source="brief" validate={required()} />
      <RichTextInput source="description" validate={required()} />

      {/* i18n fields */}
      <TextField label="Translation Fields" />
      <TextInput
        label="Name(English)"
        source="i18n.en.name"
        validate={required()}
      />
      <TextInput
        label="Brief(English)"
        source="i18n.en.brief"
        validate={required()}
      />
      <TextInput
        label="Description(English)"
        source="i18n.en.description"
        validate={required()}
      />
      <TextInput label="Name(Assamese)" source="i18n.as.name" />
      <TextInput label="Brief(Assamese)" source="i18n.as.brief" />
      <TextInput label="Description(Assamese)" source="i18n.as.description" />
      <TextInput label="Name(Hindi)" source="i18n.hi.name" />
      <TextInput label="Brief(Hindi)" source="i18n.hi.brief" />
      <TextInput label="Description(Hindi)" source="i18n.hi.description" />
      {/* end i18n fields */}
      <NumberInput source="price" />
      <NumberInput source="rank" label="Display Rank" />
      <BooleanInput label="Show" source="show" initialValue={true} />
      <BooleanInput label="Featured" source="featured" initialValue={false} />
      <ReferenceArrayInput label="Package" source="package" reference="package">
        <SelectArrayInput
          options={{ fullWidth: true }}
          optionText="name"
          optionValue="id"
        />
      </ReferenceArrayInput>
      {/* <ReferenceInput source="category" reference="category">
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput> */}
    </SimpleForm>
  </Edit>
);
