import React from "react";
import {
  List,
  Datagrid,
  DateField,
  TextField,
  ShowButton,
  EditButton,
} from "react-admin";

export const ListServiceRequest = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField label="Requested By" source="user" />
      <TextField label="Status" source="requestStatus" />
      <TextField label="Paid/Unpaid" source="paymentStatus" />
      <DateField label="Call Date" source="confirmationCallDate" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
