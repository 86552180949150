import * as React from "react";
import { List, Datagrid, TextField, ShowButton, ReferenceField } from 'react-admin';

export const ListQuery = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="query" />
            <ReferenceField
                source="user.id"
                reference="user"
                label="User"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <ShowButton />
        </Datagrid>
    </List>
);