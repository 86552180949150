import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  required,
  SelectInput,
  choices,
} from "react-admin";
import { S3ImageInput } from "../shared/input";

const validateStatus = choices(
  ["ACTIVE", "INACTIVE"],
  "Choose one of the options."
);

export const CreateSevak = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Sevak Name" source="name" validate={required()} />
      <S3ImageInput source="avatar" level="public" validate={required()} />
      <TextInput label="Sevak Phone" source="phone" validate={required()} />
      <TextInput
        label="Sevak Designation/Job Title"
        source="designation"
        validate={required()}
      />
      <TextInput label="Experience" source="experience" validate={required()} />
      {/* i18n fields */}
      <TextField label="Translation Fields" />
      <TextInput
        label="Name(English)"
        source="i18n.en.name"
        validate={required()}
      />
      <TextInput
        label="Designation(English)"
        source="i18n.en.designation"
        validate={required()}
      />
      <TextInput
        label="Experience(English)"
        source="i18n.en.experience"
        validate={required()}
      />
      <TextInput label="Name(Assamese)" source="i18n.as.name" />
      <TextInput label="Designation(Assamese)" source="i18n.as.designation" />
      <TextInput label="Experience(Assamese)" source="i18n.as.experience" />
      <TextInput label="Name(Hindi)" source="i18n.hi.name" />
      <TextInput label="Designation(Hindi)" source="i18n.hi.designation" />
      <TextInput label="Experience(Hindi)" source="i18n.hi.experience" />
      {/* end i18n fields */}
      <SelectInput
        label="Status"
        source="accountStatus"
        choices={[
          { id: "ACTIVE", name: "Active" },
          { id: "INACTIVE", name: "Inactive" },
        ]}
        validate={validateStatus}
      />
      <TextField label="Sevak Average Rating" source="averageRating" />
    </SimpleForm>
  </Create>
);
