import React from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	SelectInput,
	required,
	email,
	choices,
	SaveButton,
	ReferenceArrayInput
} from "react-admin";

import SelectArrayInput from "../shared/SelectArrayInput";

const validateName = required();
const validateEmail = [required(), email()];
const validateGender = choices(
	["male", "female", "other"],
	"Choose one of the options."
);

export const CreateUser = props => (
	<Create {...props}>
		<SimpleForm>
			<TextInput label="Full Name" source="name" validate={required()} />
			<TextInput
				label="Email"
				source="email"
				validate={[required(), email()]}
			/>
			<TextInput source="age" label="Age" validate={required()} />
			<TextInput source="phone" label="phone" validate={required()} />
			<SelectInput
				label="Gender"
				source="gender"
				choices={[
					{ id: "male", name: "Male" },
					{ id: "female", name: "Female" },
					{ id: "other", name: "Other" }
				]}
				validate={validateGender}
			/>
			<ReferenceArrayInput source="subscription" reference="subscriber">
				<SelectArrayInput
					label="Subscriptions"
					optionText="brief"
					optionValue="id"
				/>
			</ReferenceArrayInput>
			<ReferenceArrayInput source="appointments" reference="appointment">
				<SelectArrayInput
					label="Appointments"
					optionText="date"
					optionValue="id"
				/>
			</ReferenceArrayInput>
		</SimpleForm>
	</Create>
);
