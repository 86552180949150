import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
  DateInput,
  required,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
} from "react-admin";

export const CreateSubscription = (props) => (
  <Create {...props}>
    <SimpleForm>
      <DateInput source="date" validate={required()} />
      <TextInput multiline source="purpose" validate={required()} />
    </SimpleForm>
  </Create>
);
