import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectInput,
  choices,
  required,
  email,
  regex
} from "react-admin";
import SelectArrayInput from "../shared/SelectArrayInput";

const validateGender = choices(
  ["Male", "Female", "Pefer not to answer"],
  "Choose one of the options."
);

const validateLanugage = choices(
  ["Assamese", "English"],
  "Choose one of the options."
);

const validatePhone = regex(/^(\+91)\d{10}$/, 'Must be a valid phone number starting with +91');

export const EditUser = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {/* <TextInput label="Name" source="name" validate={required()}/>
      <TextInput label="" source="email" validate={[required(), email()]} />
      <TextInput source="age" validate={required()}/>
      <TextInput source="gender" validate={required()}/> */}
      <TextInput label="Full Name" source="name" validate={required()} />
      <TextInput
        label="Email"
        source="email"
        validate={[required(), email()]}
      />
      <TextInput source="phone" label="Phone" validate={validatePhone} />
      <TextInput source="age" label="Age" validate={required()} />
      <SelectInput
        label="Gender"
        source="gender"
        choices={[
          { id: "Male", name: "Male" },
          { id: "Female", name: "Female" },
          { id: "Pefer not to answer", name: "Pefer not to answer" },
        ]}
        validate={validateGender}
      />
      <SelectInput
        label="Language"
        source="language"
        choices={[
          { id: "Assamese", name: "Assamese" },
          { id: "English", name: "English" },
        ]}
        validate={validateLanugage}
      />
      {/* <ReferenceArrayInput source="subscription" reference="subscriber">
        <SelectArrayInput
          label="Subscriptions"
          optionText="brief"
          optionValue="id"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="appointments" reference="appointment">
        <SelectArrayInput
          label="Appointments"
          optionText="date"
          optionValue="id"
        />
      </ReferenceArrayInput> */}
    </SimpleForm>
  </Edit>
);
